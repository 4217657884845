import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  Observable,
  of,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateServiceOrder, ICreateServiceOrder } from './shared/interfaces/posts.interface';
import { ServiceProvider } from './shared/interfaces/serviceProvider';
import { IServiceProvider } from './shared/interfaces/service-provider';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { CurrentUser, UserPayLoad } from './shared/interfaces/currentUser';
import {
  ICustomerOrders,
  IMyOrder,
  IServiceOrders,
  OrderList,
  OrderListDetails,
  OrderStatus,
  OrderStatusUpdate,
} from './shared/interfaces/orderList';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ServiceListingDetailsResponse,
  ServiceListingsResponse,
  ServiceData,
  IServiceListing,
} from './shared/interfaces/serviceListing/serviceListing';
import {
  DeletePackage,
  Packages,
  PackagesResponse,
} from './shared/interfaces/packages';
import { FacilitiesAndToolsResponse } from './shared/interfaces/facilititesAndTools';
import { LoadingService } from './shared/state/loading.service';
import { CategoryResponse } from './shared/interfaces/category';
import { IAllSubCategories, ICertifications, IOccupations, IProperty, IServiceSubCategory, ISkills, ISpecializations, ISubCategories, ISubCategory } from './shared/interfaces/sub-categories';
import { IServiceProviderData, IServiceProviders as IServiceBaseResponse } from './shared/interfaces/provider';
import { IData, IJobLists } from './shared/interfaces/list-a-job';
import { ICategories, ICategoriesData } from './shared/interfaces/ServiceCategories';
import { Ireviews, Review } from './shared/interfaces/reviews';
import { ICreateRefundRequest, IRefunds } from './shared/interfaces/refunds';
import { IProviderReviews } from './shared/interfaces/provider-reviews';
import { IJobDetails } from './shared/interfaces/jobDetails';
// import { IProviderActivities } from './shared/interfaces/ProviderActivities';
// import { TotalSpending } from './shared/interfaces/totalSpendingInWM';
import { IMostPopularServices } from './shared/interfaces/MostPopularServices';
import { IAllServiceProviders } from './shared/interfaces/serviceProviders';
import { IServiceOrderDetails, ProcessOrder } from './shared/interfaces/serviceOrderDetails';
import { TotalSpending } from './shared/interfaces/totalSpendingInWM';
import { IRefund } from './shared/interfaces/refund';
import { IApplicationDetails } from './shared/interfaces/applications';
import { ICustomerLists } from './shared/interfaces/customerList';
import { ServiceProviderDetailsUsers } from './shared/interfaces/ServiceProviderDetails';
import { IServiceReviews } from './shared/interfaces/serviceReview';
import { IWriteProviderReview } from './shared/interfaces/provider.review';
import { IServiceProviderReviews } from './shared/interfaces/ProviderReview';
import { IJobApplication } from './shared/interfaces/jobApplication';
import { IBookedJobs } from './shared/interfaces/bookedJob';
import { IUserResponse, User } from './shared/interfaces/user';
import { workHistory } from './shared/interfaces/workHistory';
import { IServiceData } from './shared/interfaces/serviceListing/Service-Listing';
import { IServiceTypeSkills } from './shared/interfaces/serviceTypeDetails';
import { INewSkill } from './shared/interfaces/skill';
import { ServicesViewed } from './shared/interfaces/services-viewed';
import { serviceProviderDetails } from './shared/interfaces/service-providers-details';
import { IServiceOffer, ISendJobOffer } from './shared/interfaces/createOffer';
import { IEducationLevels, IEducationLevelsResponse } from './shared/interfaces/educations';
import { ProviderOffer } from './shared/interfaces/jobOffer';
import { CustomerOffer } from './shared/interfaces/CustomerOffer';
import { Listing } from './shared/interfaces/Listings';
import { OfferData } from './shared/interfaces/offerDetails';
import { ITags } from './shared/interfaces/createJob';
import { MessageService } from './pages/messages/message.service';
import { IUserDetailsResponse } from './shared/interfaces/userObject';


@Injectable({
  providedIn: 'root',
})
export class CommonServiceService {
  getProfilepic(wmUniqueId){
    return this.http.get<any>(`${environment.usrUrl}/user/profile-pic/${wmUniqueId}`);
  }

  getUserDetailById(id: number) {
    return this.http.get<IUserDetailsResponse>(`${environment.usrUrl}/user/info/${id}`);
  }

  getUserDetailsById(id: number) {
    return this.http.get<IUserResponse>(`${environment.apiUrl}/admin/user/${id}`);
  }

  postImageToDatabase(imageFile: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', imageFile, imageFile.name);

    // Change the URL according to the Swagger link you want to post to
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
    // const headers = new HttpHeaders();headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${environment.apiUrl}/private/user/profile-picture/upload`, imageFile, { headers }).pipe(
      catchError((error) => {
        console.log('error posting image: ', error);
        this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
        return EMPTY;
      })
    );
  }

  getProvidersReviewById(id: any) {
    throw new Error('Method not implemented.');
  }
  messages: '';
  SERVER_URL: string = environment.apiUrl;
  message: BehaviorSubject<String>;

  private currentUser: CurrentUser = JSON.parse(
    localStorage.getItem('currentUser')
  );

  constructor(
    public http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private loadingService: LoadingService,
    private messageService: MessageService,
  ) {
    this.message = new BehaviorSubject(this.messages);
  }

  deleteJobById(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete<IJobLists>(`${environment.apiUrl}/private/service/job/{id}?id=${id}`, { headers });
  }
  // private/service/job/{id}?id=86
  // deleteApplicationById(id: number): Observable<{}> {
  //   const headers = new HttpHeaders ({'Content-Type' : 'application/json'});     //specifies that the content will be in json format
  //   return this.http.delete<IApplicationDetails>(`${environment.apiUrl}/private/service/application/{id}?id=${id}`, { headers })
  // }

  // getAllJobs(
  //   pageNumber: number,
  //   pageSize: number,
  //   sortBy: string = 'title',
  //   sortOrder: string = 'ASCENDING'
  // ) {
  //   const params = new HttpParams()
  //     .set('pageNumber', (pageNumber).toString())
  //     .set('pageSize', pageSize.toString())
  //     .set('sortBy', sortBy)
  //     .set('sortOrder', sortOrder);
  //   return this.http.get<IJobLists>(`${environment.apiUrl}/private/service/jobs`, {
  //     params,
  //   });

  // }
  getAllJobs(
    pageNumber: number,
    pageSize: number,
    keyword?: string,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING',
    serviceCategoryId?: number | null,
    serviceSubCategoryId?: number | null,
    serviceTypeId?: number | null
  ): Observable<IData> {
    this.loadingService.setLoadingState(true);

    let params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    if (keyword) params = params.set('keyword', keyword);
    if (serviceCategoryId) params = params.set('serviceCategoryId', serviceCategoryId.toString());
    if (serviceSubCategoryId) params = params.set('serviceSubCategoryId', serviceSubCategoryId.toString());
    if (serviceTypeId) params = params.set('serviceTypeId', serviceTypeId.toString());

    return this.http
      .get<IJobLists>(
        `${environment.apiUrl}/service/jobs/all`,
        { params }
      )
      .pipe(
        map(({ data }) => {
          this.loadingService.setLoadingState(false);
          return data;
        }),
        tap((val) => console.log('all Jobs ', val)),
        catchError((error) => {
          console.log('error get all Jobs ', error);
          this.loadingService.setLoadingState(false);
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          return EMPTY;
        })
      );
  }



  getJobDetails(id) {
    return this.http.get<IJobDetails>(
      `${environment.apiUrl}/private/service/job/${id}`
    );
  }
  getAllServicesViewed(id) {
    return this.http.get<ServicesViewed>(
      `${environment.apiUrl}/private/service/service-listing/services-viewed/${id}`
    );
  }

  // getAllReviews(pageNumber: number = 0, pageSize: number = 10) {
  //   const params = new HttpParams()
  //     .set('pageNumber', (pageNumber++).toString())
  //     .set('pageSize', pageSize.toString());
  //   return this.http.get<Ireviews>(
  //     `${environment.apiUrl}/private/service/service-order/completed-orders`,
  //     { params }
  //   );
  // }
  getAllReviews(pageNumber: number = 0, pageSize: number) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<Ireviews>(
      `${environment.apiUrl}/private/service/service-reviews`,
      { params }
    );
  }

  getCustomersServiceReviews(userId, pageNumber: number = 0, pageSize: number = 1000000) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<Ireviews>(
      `${environment.apiUrl}/private/service/service-reviews/customer/${userId}`, { params }
    );
  }

  getAllRefunds(pageNumber: number = 0, pageSize: number) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<IRefunds>(
      `${environment.apiUrl}/service/refund-requests`,
      { params }
    );
  }


  deleteRefundById(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete<IRefunds>(`${environment.apiUrl}/private/refund-requests/${id}`);
  }


  deleteServiceById(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete<Listing>(`${environment.apiUrl}/private/service/service-listing/${id}`);
  }
  // getRefundsById(userId) {
  //   return this.http.get<IRefunds>(
  //     `${environment.apiUrl}/service/refund-requests/customer/${userId}`
  //   );
  // }
  getRefundsById(
    userId: number,
    pageNumber: number = 0,
    pageSize: number = 1000000,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING') {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IRefunds>(`${environment.apiUrl}/service/refund-requests/customer/${userId}`, { params })
  }


  // getRefundById(serviceProviderId) {
  //   return this.http.get<IRefund>(
  //     `${environment.apiUrl}/service/refund-requests/provider/refund-requests/${serviceProviderId}`
  //   );
  // }
  getRefundById(providerId: number, pageNumber: number = 0,
    pageSize: number = 1000000,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING') {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IRefund>(`${environment.apiUrl}/service/refund-requests/provider/refund-requests/${providerId}`, { params })
  }

  getAllUsers(pageNumber: number = 0,
    pageSize: number,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING') {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<ICustomerLists>(`${environment.usrUrl}/users`, { params })
  }
  getAllServiceProviderReviews(pageNumber: number = 0, pageSize: number, sortBy: string = "id", sortOrder: string = "DESCENDING") {
    const params = new HttpParams().set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IServiceProviderReviews>(`${environment.apiUrl}/private/service/service-provider-reviews`, { params })
  }
  getServiceOrderDetailsById(id) {
    return this.http.get<IServiceOrderDetails>(`${environment.apiUrl}/service/service-orders/${id}`)
  }

  getDataById(id) {
    return this.http.get<ISubCategory>(
      `${environment.apiUrl}/service/sub-category/${id}`
    );
  }
  getData() {
    return this.http.get<any>(
      `${environment.apiUrl}/service/qualification`
    );
  }



  // getProviderReviews(serviceProviderId:number
  //   , pageNumber: number = 0, pageSize: number = 0,sortBy: string = 'id',
  //   sortOrder: string = 'DESCENDING'){
  //   const params = new HttpParams().set('pageNumber', (pageNumber++).toString())
  //                                  .set('pageSize', (pageSize).toString())
  //                                  .set('pageSize', (sortBy));
  //                                  .set('sortOrder', sortOrder);
  //   return this.http.get<IProviderReviews>(`${environment.apiUrl}/service/service-provider-reviews/service-provider/${serviceProviderId
  //   }`, {params})
  // }

  getProviderReviews(serviceProviderId: number, pageNumber: number = 0, sortBy: string = "id", sortOrder: string = "DESCENDING", pageSize: number = 1000000) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IProviderReviews>(`${environment.apiUrl}/service/service-provider-reviews/service-provider/${serviceProviderId}`, { params })
  }

  getProviderReviewsByCustomerId(customerId, pageNumber: number = 0, sortBy: string = "id", sortOrder: string = "DESCENDING", pageSize: number = 1000000) {
    const params = new HttpParams().set('pageNumber', (pageNumber++).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('pageSize', pageSize.toString());

    return this.http.get<IProviderReviews>(`${environment.apiUrl}/service/service-provider-reviews/customer/${customerId}`, { params })
  }


  getTotalSpending() {
    return this.http.get<any>(`${environment.apiUrl}/private/service/service-order/total-spending`)
  }


  getMostPopularServices() {
    return this.http.get<IMostPopularServices>(`${environment.apiUrl}/service/popular-service`)
  }

  getAllProviders(pageNumber: number = 1) {
    const params = new HttpParams().set('pageNumber', (pageNumber++).toString())
    return this.http.get<IAllServiceProviders>(`${environment.apiUrl}/private/service/service-providers`, { params })
  }

  writeRefundRequest(request: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ICreateRefundRequest>(`${environment.apiUrl}/service/refund-requests`, request, { headers })
  }

  updateItem(updatedItem: IServiceSubCategory) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(
      `${environment.apiUrl}/private/service/sub-category`,
      updatedItem,
      { headers }
    ); // remember `${}/${}` cone,dollar braces/dollar braces.   Also remember headers has to be in braces {headers}
  }
  nextmessage(data) {
    this.message.next(data);
  }

  update(id, status, list) {
    let filter = list.filter((a) => a.patient_id === id);
    if (filter.length != 0) {
      filter['status'] = status;
    }
  }

  getServices() {
    return this.http.get(this.SERVER_URL + 'services');
  }

  getSpeciality() {
    return this.http.get(this.SERVER_URL + 'specialityList');
  }

  getAllReports() {
    return this.http.get(this.SERVER_URL + 'all_reports');
  }

  getPendingReports() {
    return this.http.get(this.SERVER_URL + 'pending_reports');
  }

  getInprogressReports() {
    return this.http.get(this.SERVER_URL + 'inprogress_reports');
  }

  getCompletedReports() {
    return this.http.get(this.SERVER_URL + 'completed_reports');
  }

  getRejectedReports() {
    return this.http.get(this.SERVER_URL + 'rejected_reports');
  }

  getCanceledReports() {
    return this.http.get(this.SERVER_URL + 'canceled_reports');
  }

  getRatings() {
    return this.http.get(this.SERVER_URL + 'ratings');
  }

  getSubscription() {
    return this.http.get(this.SERVER_URL + 'subscriptions');
  }

  getUsers() {
    return this.http.get(this.SERVER_URL + 'users');
  }

  getWalletReport() {
    return this.http.get(this.SERVER_URL + 'wallet_report');
  }

  getWalletHistory() {
    return this.http.get(this.SERVER_URL + 'wallet_history');
  }

  createSpeciality(data) {
    return this.http.post(`${this.SERVER_URL + 'specialityList'}`, data);
  }

  updateSpeciality(data, id) {
    return this.http.put(`${this.SERVER_URL + 'specialityList'}/${id}`, data);
  }

  deleteSpeciality(id) {
    return this.http.delete(`${this.SERVER_URL + 'specialityList'}/${id}`);
  }

  getAppointments() {
    return this.http.get(this.SERVER_URL + 'appointments');
  }

  getNotifications() {
    return this.http.get(this.SERVER_URL + 'notifications');
  }

  //---- Service categories start ----//
  getCategories() {
    return this.http.get<any>(this.SERVER_URL + '/service/categories');
  }
  //---- Service categories end ----//

  //---- Service sub-categories start ----//
  getSubCategories() {
    return this.http.get(this.SERVER_URL + 'sub_categories');
  }
  //---- Service sub-categories end ----//

  updateAppointment(data, id) {
    return this.http.put(`${this.SERVER_URL + 'appointments'}/${id}`, data);
  }

  createAppointment(params) {
    return this.http.post(`${this.SERVER_URL + 'appointments'}`, params);
  }

  getComments() {
    return this.http.get(this.SERVER_URL + 'comments');
  }

  createComment(params) {
    return this.http.post(this.SERVER_URL + 'comments', params);
  }

  getTransactions() {
    return this.http.get(this.SERVER_URL + 'transactions');
  }

  getPlans() {
    return this.http.get(this.SERVER_URL + 'plans');
  }

  getAvailableDays() {
    return this.http.get(this.SERVER_URL + 'available_days');
  }

  deleteTransaction(id) {
    return this.http.delete(`${this.SERVER_URL + 'transactions'}/${id}`);
  }

  deleteReview(id) {
    return this.http.delete(`${this.SERVER_URL + 'reviews'}/${id}`);
  }

  // -------------- OUR APIs -------------------//

  //basic get operation
  retrieveStates() {
    return this.http.get<any>(`${environment.apiUrl}/address/states`);
  }

  // get operation with a path variable
  retrieveLgasByStateId(id) {
    return this.http.get<any>(`${environment.apiUrl}/address/lgas/state/${id}`);
  }

  // get operation with one query parameter
  getTransactionTypes(active: boolean = false) {
    return this.http.get<any>(
      `${environment.apiUrl}/transaction-types?active=${active}`
    );
  }

  // get operation with multiple request/query params
  retrieveCategories(
    pageNumber: number,
    pageSize: number,
    sortBy: string = 'title',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<any>(`${environment.apiUrl}/service/categories`, {
      params,
    });
  }
  getAllCategories(
    pageNumber: number = 0,
    pageSize: number = 10000,
    sortBy: string = 'title',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<any>(`${environment.apiUrl}/service/categories`, {
      params,
    });
  }

  retrieveAllCategories(): Observable<CategoryResponse> {
    return this.http.get<CategoryResponse>(
      `${environment.apiUrl}/service/categories?pageNumber=1&pageSize=1000&sortBy=title&sortOrder=ASCENDING`
    );
  }
  retrieveEverySubCategories(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/service/sub-categories?categoryId=0&pageNumber=1&pageSize=1000&sortBy=name&sortOrder=ASCENDING`
    );
  }

  retrieveSubCategories(
    categoryId: number = 0,
    pageNumber?: number,
    pageSize: number = 10,
    sortBy: string = 'name',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('categoryId', categoryId.toString())
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<any>(`${environment.apiUrl}/service/sub-categories`, {
      params,
    });
  }

  retrieveAllSubCategories(categoryId: number) {
    return this.http.get<any>(`${environment.apiUrl}/service/sub-categories?categoryId=${categoryId}&pageNumber=1&pageSize=1000&sortBy=name&sortOrder=ASCENDING`)
  }


  getAllSubCategories(pageSize: number = 100000000,
  ): Observable<IAllSubCategories> {
    const params = new HttpParams().set('pageSize', pageSize.toString())

    return this.http.get<IAllSubCategories>(
      `${environment.apiUrl}/service/sub-categories`, { params }
    );
  }
  getCategoriesById(id) {
    var url = environment.apiUrl + '/service/categories/{id}?id=' + id;
    return this.http.get<ICategories<ICategoriesData>>(url);
  }

  retrieveServiceTypes(
    subCategroryId: number = 0,
    pageNumber: number,
    pageSize: number = 10,
    sortBy: string = 'name',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('subCategroryId', subCategroryId.toString())
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<any>(`${environment.apiUrl}/service/service-types`, {
      params,
    });
  }

  getAllServiceTypes(subCategoryId: number) {
    return this.http.get<any>(`${environment.apiUrl}/service/service-types?pageNumber=1&pageSize=1000&sortBy=name&sortOrder=ASCENDING&subCategoryId=${subCategoryId}`)
  }
  getEveryServiceTypes() {
    return this.http.get<any>(`${environment.apiUrl}/service/service-types?pageNumber=1&pageSize=1000&sortBy=name&sortOrder=ASCENDING`)
  }

  retrieveServiceListing(
    // subCategoryId: number = 0,
    pageNumber: number,
    pageSize: number,
    sortBy: string = 'name',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      // .set('subCategoryId', subCategoryId.toString())
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    console.log(`${environment.apiUrl}/service/service-listings`);

    return this.http.get<any>(
      `${environment.apiUrl}/service/service-listings`,
      {
        params,
      }
    );
  }

  getServiceTypeDetials(serviceTypeId: number) {
    return this.http.get<any>(
      `${environment.apiUrl}/service/service-types/${serviceTypeId}`
    );
  }

  retrieveServiceProviders(
    pageNumber: number = 0,
    pageSize: number = 1000000,
    serviceTypeId: number = 0,
    sortBy: string = 'id',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('serviceTypeId', serviceTypeId.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IServiceProvider>(
      `${environment.apiUrl}/service/service-providers`,
      { params }
    );
  }
  getAdminServiceProviderList(
    pageNumber: number = 0,
    pageSize: number,
    serviceTypeId: number = 0,
    sortBy: string = 'id',
    sortOrder: string = 'ASCENDING'
  ): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('serviceTypeId', serviceTypeId.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<any>(
      `${environment.apiUrl}/service/service-providers`,
      { params }
    );
  }


  //gets all service providers
  getServiceProviders() {
    return this.http.get<any>(`${environment.apiUrl}/service/service-providers?pageNumber=1&pageSize=1000&serviceTypeId=0&sortBy=id&sortOrder=ASCENDING`)
  }

  //get all service listings without pagination
  // getListings(){
  //   return this.http.get<ServiceListingsResponse>(`${environment.apiUrl}/service/service-listings?pageNumber=1&pageSize=1000&sortBy=dateCreated&sortOrder=DESCENDING`)
  // }
  getListings(
    pageNumber: number,
    pageSize: number,

    sortBy: string = 'id',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())

      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<ServiceListingsResponse>(
      `${environment.apiUrl}/service/service-listing`,
      { params }
    );
  }
  /* retrieveServiceListing(
    pageNumber: number,
    pageSize: number = 9,
    sortBy: string = 'dateCreated',
    sortOrder: string = 'DESCENDING',
    keyword?: string,
    serviceCategoryId?: number,
    serviceSubCategoryId?: number,
    serviceTypeId?: number
  ) {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    if (keyword) params = params.set('keyword', keyword);
    if (serviceCategoryId)
      params = params.set('serviceCategoryId', serviceCategoryId.toString());
    if (serviceSubCategoryId)
      params = params.set(
        'serviceSubCategoryId',
        serviceSubCategoryId.toString()
      );
    if (serviceTypeId)
      params = params.set('serviceTypeId', serviceTypeId.toString());

    return this.http
      .get<any>(`${environment.apiUrl}/service/service-listings`, { params })
      .pipe(
        catchError((error) => {
          this.errorSnackBar(error);
          console.log('error message', error);
          return EMPTY;
        })
      );
  } */

  getAllServiceListings(
    pageNumber: number,
    pageSize: number,
    keyword?: string,
    sortBy: string = 'dateCreated',
    sortOrder: string = 'DESCENDING',
    serviceCategoryId?: number,
    serviceSubCategoryId?: number,
    serviceTypeId?: number
  ): Observable<ServiceData> {
    this.loadingService.setLoadingState(true);
    let params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    if (keyword) params = params.set('keyword', keyword);
    if (serviceCategoryId)
      params = params.set('serviceCategoryId', serviceCategoryId.toString());
    if (serviceSubCategoryId)
      params = params.set(
        'serviceSubCategoryId',
        serviceSubCategoryId.toString()
      );
    if (serviceTypeId)
      params = params.set('serviceTypeId', serviceTypeId.toString());

    return this.http
      .get<ServiceListingsResponse>(
        `${environment.apiUrl}/service/service-listing/all`,
        { params }
      )
      .pipe(
        map(({ data }) => {
          this.loadingService.setLoadingState(false);
          return data;
        }),
        tap((res) => console.log('all listings: ', res)),
        catchError((error) => {
          console.log('error getting all listings: ', error);
          this.loadingService.setLoadingState(false);
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          this.handleError(error);
          return EMPTY;
        })
      );
  }

  errorIs500: boolean = false;
  errorMessage: string = '';

  handleError(error: any) {
    if (error.status === 500) {
      this.errorIs500 = true;
      this.errorMessage = '';
      console.log("Error Handler");
    } else {
      this.errorIs500 = false;
      this.errorMessage = error.message || 'An unknown error occurred';
      console.log("Error Handler");
    }
  }

  // Job
  getAllJobListings(
    pageNumber: number = 0,
    keyword?: string,
    sortBy: string = 'dateCreated',
    sortOrder: string = 'DESCENDING',
    serviceCategoryId?: number,
    serviceSubCategoryId?: number,
    serviceTypeId?: number
  ): Observable<ServiceData> {
    this.loadingService.setLoadingState(true);
    const pageSize: number = 9;
    let params = new HttpParams()
      .set('pageNumber', (pageNumber + 1).toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    if (keyword) params = params.set('keyword', keyword);
    if (serviceCategoryId)
      params = params.set('serviceCategoryId', serviceCategoryId.toString());
    if (serviceSubCategoryId)
      params = params.set(
        'serviceSubCategoryId',
        serviceSubCategoryId.toString()
      );
    if (serviceTypeId)
      params = params.set('serviceTypeId', serviceTypeId.toString());

    if (keyword) params = params.set('keyword', keyword);
    if (serviceCategoryId)
      params = params.set('serviceCategoryId', serviceCategoryId.toString());
    if (serviceSubCategoryId)
      params = params.set(
        'serviceSubCategoryId',
        serviceSubCategoryId.toString()
      );
    if (serviceTypeId)
      params = params.set('serviceTypeId', serviceTypeId.toString());

    return this.http
      .get<ServiceListingsResponse>(
        `${environment.apiUrl}/service/service-listing/all`,
        { params }
      )
      .pipe(
        map(({ data }) => {
          this.loadingService.setLoadingState(false);
          return data;
        }),
        tap((val) => console.log('all listings ', val)),
        catchError((error) => {
          console.log('error get all listings ', error);
          this.loadingService.setLoadingState(false);
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          return EMPTY;
        })
      );
  }
  // get serviceListing details
  retrieveServiceListingDetails(id: number): Observable<ServiceListingDetailsResponse> {
    return this.http
      .get<ServiceListingDetailsResponse>(
        `${environment.apiUrl}/service/service-listing/detail/${id}`
      )
      .pipe(
        catchError((error) => {
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          console.log('error message', error);
          return EMPTY;
        })
      );
  }

  // get provider details for serviceproviderId
  getServiceProviderDetails(id: number): Observable<any> {
    return this.http.get<serviceProviderDetails>(
      `${environment.apiUrl}/service-providers/detail/${id}`
    );
  }




  retrieveProviderDetails(id: number): Observable<any> {
    return this.http.get<ServiceProviderDetailsUsers>(
      `${environment.apiUrl}/private/service-providers/${id}`
    );
  }

  // get user details bu userID
  retrieveUserDetails(id: number): Observable<UserPayLoad> {
    return this.http.get<any>(`${environment.apiUrl}/admin/user/${id}`);
  }

  // retrieve serviceListing facilities and tools
  retrieveFacilitiesandTools(
    id: number
  ): Observable<FacilitiesAndToolsResponse> {
    return this.http.get<FacilitiesAndToolsResponse>(
      `${environment.apiUrl}/service/facilities-and-tools/${id}`
    );
  }
  // retrieve serviceListing addOns
  retrieveAddOns(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/service/add-on/${id}`);
  }

  // create a service order,
  createServiceOrder(serviceOrder: ICreateServiceOrder): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<ICreateServiceOrder>(
      `${environment.apiUrl}/service/service-order`,
      serviceOrder, { headers }
    );
  }

  //ServiceReview

  postCustomerReview(review: IServiceReviews) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<IServiceReviews>(`${environment.apiUrl}/private/service/service-reviews`, review, { headers })
  }

  // postProviderReview( id, reviews: IWriteProviderReview) {
  //   const headers = new HttpHeaders ({'Content-Type' : 'application/json'});
  //   return this.http.post<IWriteProviderReview>(`${environment.apiUrl}/service/service-provider-reviews?userId=${id}`, reviews, { headers })
  // }

  //  ProvidersReview

  postProviderReview(reviews: IWriteProviderReview) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<IWriteProviderReview>(`${environment.apiUrl}/private/service/service-provider-reviews`, reviews, { headers })
  }

  getServiceOrderId(id) {
    return this.http.get<any>(`${environment.apiUrl}/private/service/service-orders/${id}`)
  }

  //processes serviceOrder by orderId for payment
  processServiceOrderId(processOrder: ProcessOrder) {
    return this.http.post(`${environment.apiUrl}/private/service/service-orders/process`, processOrder)
  }

  // Get customer ordersList

  retrieveOrderLists(
    pageNumber: number,
    pageSize: number,
    sortBy: string = 'id',
    sortOrder: string = 'ASCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IServiceOrders>(
      `${environment.apiUrl}/private/service/service-orders`,
      {
        params,
      }
    );
  }

  getServiceProviderData(providerId) {
    return this.http.get<IServiceBaseResponse<IServiceProviderData>>(
      `${environment.apiUrl}/private/service-providers/${providerId}`
    );
  }
  getUserName(Id) {
    return this.http.get<any>(
      `${environment.apiUrl}/private/service-providers/${Id}`
    );
  }

  getCustomerOrderList(userId: number) {
    return this.http.get<ICustomerOrders>(
      `${environment.apiUrl}/service/service-orders/user/${userId}`
    );
  }
  // Provider Job Offer

  getProviderJobOffer(
    serviceProviderId: number,
    pageNumber: number = 0,
    pageSize: number = 10,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<ProviderOffer>(
      `${environment.apiUrl}/service/job-offers/service-provider/${serviceProviderId}`,
      {
        params,
      }
    )
  }
  // Customer Offer
  getCustomerJobOffer(
    userId: number,
    pageNumber: number = 0,
    pageSize: number = 1000000,
    sortBy: string = 'id',
    sortOrder: string = 'DESCENDING'
  ) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<CustomerOffer>(
      `${environment.apiUrl}/service/job-offers/customer/${userId}`,
      {
        params,
      }
    );
  }




  getCustomerJobOfferDetails(offerId: number) {
    return this.http.get<OfferData>(
      `${environment.apiUrl}/service/job-offers/${offerId}`
    );
  }

  getProviderJobOfferDetails(offerId: number) {
    return this.http.get<OfferData>(
      `${environment.apiUrl}/service/job-offers/${offerId}`
    );
  }

  // Get User orders (customer)
  // retrieveCustomerOrderList(userId: number): Observable<OrderListDetails[]> {
  //   return this.http
  //     .get<any>(
  //       `${environment.apiUrl}/service/service-orders/user/${userId}`
  //     )
  //     .pipe(
  //       // tap((orderResponse) => console.log('original response ', orderResponses)),
  //       concatMap((order) => from(order.data)),
  //       tap((order) => console.log('concatMat ', order)),
  //       mergeMap((order: OrderList) =>
  //         combineLatest([
  //           this.retrieveUserDetails(this?.usersId),
  //           this.retrieveServiceListingDetails(order?.id),
  //         ]).pipe(
  //           catchError((error) => {
  //             this.orderListsnackBarError(error, order);
  //             return EMPTY;
  //           }),
  //           tap((res) => console.log('server response ', res)),
  //           map(
  //             ([customer, serviceListing]) =>
  //               ({
  //                 id: order.id,
  //                 orderCreation: order.orderCreation,
  //                 amount: order.amount,
  //                 customerName:
  //                   customer.data.user.firstName +
  //                   ' ' +
  //                   customer.data.user.lastName,
  //                 customerPhoneNumber: customer.data.user.phoneNumber,
  //                 serviceListingId: order.serviceListingId,
  //                 customerImg:
  //                   customer.data.profileImageUrl ||
  //                   'assets/img/customer/user-01.jpg',
  //                 serviceListingImg:
  //                   serviceListing.data.pictures ||
  //                   'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
  //                 serviceListingName: serviceListing.data.name,
  //                 customerId: customer.data.user.id,
  //                 serviceProviderName:
  //                   serviceListing.data.provider?.name,
  //                 serviceProviderImage:
  //                   serviceListing.data.provider?.imageUrl ||
  //                   'assets/img/customer/user-01.jpg',
  //                 serviceProviderId:
  //                   serviceListing.data.provider?.id,
  //                 orderStatus: order.orderStatus,
  //                 serviceListingDescription:
  //                   serviceListing.data.serviceSummary,
  //               } as OrderListDetails)
  //           )
  //         )
  //       ),
  //       toArray(),
  //       // sort orderList by decending date
  //       // map((arr) => arr.sort((a, b) => new Date(b.orderCreation).valueOf() - new Date(a.orderCreation).valueOf())),
  //       tap((res) => console.log('View Model ', res)),
  //       catchError((error) => {
  //         if (error.description === 'userId exists but has no service order') {
  //           return of([]);
  //         }
  //         console.log(error);
  //         this.errorSnackBar(error);
  //         return EMPTY;
  //       })
  //     )
  //     ;
  // }
  retrieveProvidersOrderLists(
    serviceProviderId: number
  ): Observable<IMyOrder> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/service/service-orders/service-provider/${serviceProviderId}`
      )
    }

  retrieveProvidersOrderList(
    serviceProviderId: number
  ): Observable<OrderListDetails[]> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/private/service/service-orders/service-provider/${serviceProviderId}`
      )
      .pipe(
        concatMap((order) => from(order.data)),
        tap((order) => console.log('concatMat ', order)),
        mergeMap((order: OrderList) =>
          combineLatest([
            this.retrieveUserDetails(order.userId),
            this.retrieveServiceListingDetails(order.serviceListingId),
          ]).pipe(
            catchError((error) => {
              this.orderListsnackBarError(error, order);
              return EMPTY;
            }),
            tap((res) => console.log('server response ', res)),
            map(
              ([customer, serviceListing]) =>
              ({
                id: order.id,
                orderCreation: order.orderCreation,
                amount: order.amount,
                customerName:
                  customer.data.user.firstName +
                  ' ' +
                  customer.data.user.lastName,
                customerPhoneNumber: customer.data.user.phoneNumber,
                serviceListingId: order.serviceListingId,
                customerImg:
                  customer.data.profileImageUrl ||
                  'assets/img/customer/user-01.jpg',
                serviceListingImg:
                  serviceListing.data.pictures ||
                  'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
                serviceListingName: serviceListing.data.name,
                customerId: customer.data.user.id,
                serviceProviderName:
                  serviceListing.data.provider?.businessName,
                serviceProviderImage:
                  serviceListing.data.provider?.imageUrl ||
                  'assets/img/customer/user-01.jpg',
                serviceProviderId:
                  serviceListing.data.provider?.id,
                orderStatus: order.orderStatus,
                serviceListingDescription:
                  serviceListing.data.serviceSummary,
              } as OrderListDetails)
            )
          )
        ),
        toArray(),
        // sort orderList by decending date
        // map((arr) => arr.sort((a, b) => new Date(b.orderCreation).valueOf() - new Date(a.orderCreation).valueOf())),
        tap((res) => console.log('View Model ', res)),
        catchError((error) => {
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          console.log(error);
          return EMPTY;
        })
      );
  }

  // update order states
  updateServiceOrderStatus(
    orderId: number,
    status: OrderStatus
  ): Observable<OrderStatusUpdate> {
    // const params = new HttpParams()
    //   .set('orderId', orderId.toString())
    //   .set('status', status)

    return this.http
      .put<any>(
        `${environment.apiUrl}/private/service/service-order/status?id=${orderId}&status=${status}`,
        null
      )
      .pipe(
        tap((res) => console.log('Order Post ', res)),
        catchError((error) => {
          this.errorSnackBar(error.description || error.message || error.error || "Something went wrong");
          console.log(error);
          return EMPTY;
        })
      );
  }

  getPackages(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/private/service/service-package/${id}`
      )

  }


  getServicePackages(id: number): Observable<Packages> {
    return this.http
      .get<PackagesResponse>(
        `${environment.apiUrl}/private/service/service-package/${id}`
      )
      .pipe(
        tap((servicePackage) =>
          console.log('Servicepackages ', servicePackage)
        ),
        map((servicePackage: PackagesResponse) => {
          const packagesObject: Packages = JSON.parse(
            servicePackage.data[0].packageDescription
          );
          return { ...packagesObject, id: servicePackage.data[0].id };
        }),
        // tap((servicePackage) => console.log('packages object', servicePackage)),
        catchError((error) => {
          if (error.description === 'No packages found') {
            console.log('no packages found', error);
            return of(null);
          }
          // this.errorSnackBar(error.description);
          console.log('error message', error);
          return EMPTY;
        })
      );
  }

  // generate order details data structure

  // Get Service Provider details by a user
  getServiceProvider(wmUniqueId: string): Observable<ServiceProvider> {
    return this.http.get<any>(
      `${environment.apiUrl}/service/service-provider/${wmUniqueId}`
    ).pipe(
      tap((res) => {console.log("provider object gotten: " , res)},
          (error) => {console.log("Error getting provider: " , error)})
    );
  }

  uploadServiceListingImage(
    imageToUpload: File,
    serviceListingId: number
  ): Observable<any> {
    let url = `${environment.apiUrl}/private/service-listings/upload-image/${serviceListingId}`;

    const formdata: FormData = new FormData();

    formdata.append('file', imageToUpload);

    return this.http.post(url, formdata);
  }

  uploadServiceProviderImage(imageToUpload: File): Observable<any> {
    console.log(imageToUpload);

    let url = `${environment.apiUrl}/private/service-providers/upload-profile-pic`;

    const formdata: FormData = new FormData();

    formdata.append('image', imageToUpload);

    return this.http.post(url, formdata);
  }

  errorSnackBar(error, horizontalPosition?, verticalPosition?): void {
    let errMessage = error.description || error.message || error;
    // if (errMessage === 'Invalid authorization token' || 'Missing required headers') {
    if (errMessage === 'Invalid authorization token') {
      errMessage = 'Session expired. Please sign in';
      this.loadingService.setLoadingState(false);
      this.router.navigate([`/authentication/signin`]);
    }
    // else if (errMessage === error) {
    //   errMessage = 'AWS Server Error  Refresh page and try again';
    // }
    let snack = this.snackBar.open(`${errMessage}`, 'Close', {
      horizontalPosition: horizontalPosition ? horizontalPosition : 'right',
      verticalPosition: verticalPosition ? verticalPosition : 'top',
    });
    // snack.afterDismissed().subscribe(() => {
    //   console.log('This will be shown after snackbar disappeared');
    // });
    // snack.onAction().subscribe(() => {
    //   console.log('This will be called when snackbar button clicked');
    // });
  }

  // error snackbar that display error to user
  orderListsnackBarError(error: any, order: OrderList): void {
    console.log('serviceListing error message', error);
    console.log('serviceListing error request object', order);
    let snack = this.snackBar.open(
      `ERROR! Service: ${order.title}. ${error.description}.\n
                Please Contact Admin`,
      'Close'
    );
    // snack.afterDismissed().subscribe(() => {
    //   console.log('This will be shown after snackbar disappeared');
    // });
    // snack.onAction().subscribe(() => {
    //   console.log('This will be called when snackbar button clicked');
    // });
  }
  alertSnackBar(message, horizontalPosition?, verticalPosition?): void {
    let snack = this.snackBar.open(`${message}`, 'Okay', {
      horizontalPosition: horizontalPosition ? horizontalPosition : 'right',
      verticalPosition: verticalPosition ? verticalPosition : 'top',
    });
    // snack.afterOpened().subscribe(() => {
    //   console.log('Snackbar has been opened');
    // });
    // snack.afterDismissed().subscribe(() => {
    //   console.log('This will be shown after snackbar disappeared');
    // });
    // snack.onAction().subscribe(() => {
    //   console.log('This will be called when snackbar button clicked');
    // });
  }

  //get service provider listing by id
  getServiceProviderListing(serviceProviderId) {
    return this.http.get<IServiceData>(
      `${environment.apiUrl}/service/service-listings/${serviceProviderId}`
    );
  }
  //gets a review for a completed service by id
  serviceListingReviewbyId(serviceListingId) {
    return this.http.get<any>(
      `${environment.apiUrl}/service/service-reviews/service-listing/${serviceListingId}?pageNumber=0&pageSize=10&sortBy=id&sortOrder=DESCENDING`
    );
  }

  //get service provider job applications by id
  getServiceProviderApplications(serviceProviderId) {
    return this.http.get<any>(
      `${environment.apiUrl}/private/service/application/${serviceProviderId}`
    );
  }

  //get service provider work history by id
  getProviderWorkHistory(serviceProviderId, sortBy: string = "id", sortOrder: string = "DESCENDING") {
    let params = new HttpParams()
    .set('sortBy', sortBy)
    .set('sortOrder', sortOrder);

    return this.http.get<workHistory>(
      `${environment.apiUrl}/private/service/applications/history/${serviceProviderId}`, { params }
    );
  }
  //  getApplicationDetailsById(id: number): Observable<IApplicationDetails>{
  //     return this.http.get<IApplicationDetails>(`${environment.apiUrl}/service/applications/${id}`)
  //   }
  getApplicationDetailsById(id: number): Observable<IApplicationDetails> {
    return this.http.get<IApplicationDetails>(`${environment.apiUrl}/service/applications/${id}`)
  }
  deleteApplicationById(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });     //specifies that the content will be in json format
    return this.http.delete<IApplicationDetails>(`${environment.apiUrl}/private/service/application/{id}?id=${id}`, { headers })
  }
  //getProviderReviewsId(id: number): Observable<IApplicationDetails>{
  //   return this.http.get<IApplicationDetails>(`${environment.apiUrl}/service/applications/${id}`)
  // }

  getJobApplications(pageNumber: number = 0, pageSize: number, sortBy: string = "id", sortOrder: string = "DESCENDING") {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IJobApplication>(`${environment.apiUrl}/service/applications`, { params });
  }
  getBookedJobs(pageNumber: number, pageSize: number, sortBy: string = "id", sortOrder: string = "DESCENDING") {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    return this.http.get<IBookedJobs>(`${environment.apiUrl}/service/job-offers`, { params });
  }
  updateJobApplication(application: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/private/service/application/edit/${application.id}`, application);
  }
  createApplication(application: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/private/service/application`, application);
  }

  getCertifications(): Observable<ICertifications> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<ICertifications>(
      `${environment.apiUrl}/service/sub-category/service-certifications`, { headers });
  }
  getOccupations(): Observable<IOccupations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<IOccupations>(
      `${environment.apiUrl}/service/sub-category/service-occupations`, { headers });
  }
  getProperties(): Observable<IProperty> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<IProperty>(
      `${environment.apiUrl}/service/sub-category/service-properties`, { headers });
  }
  getSpecializations(): Observable<ISpecializations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<ISpecializations>(
      `${environment.apiUrl}/service/sub-category/service-specializations`, { headers });
  }
  getSkills(): Observable<INewSkill> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<INewSkill>(
      `${environment.apiUrl}/service/sub-category/service-skills`, { headers });
  }
  getTags(): Observable<ITags> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<ITags>(
      `${environment.apiUrl}/linkFromMike`, { headers });
  }
  createCustomOffer(offer: IServiceOffer) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<IServiceOffer>(`${environment.apiUrl}/service/contract-offer/send`, offer, { headers })
  }
  sendJobOffer(offer: ISendJobOffer) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ISendJobOffer>(`${environment.apiUrl}/service/job-offers`, offer, { headers })
  }

  uploadApplicationFile(files: File[], applicationId: number) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append(`files`, file);
    });
    return this.http.post(`${environment.apiUrl}/private/service/application/upload-attachment-files/${applicationId}`, formData)
  }
  getEducations() {
    return this.http.get<IEducationLevelsResponse>(
      `${environment.apiUrl}/service/levels-of-education`);
  }
  ProviderOfferDecision(wmUniqueId: string, offerId: number, offerStatus: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(`${environment.apiUrl}/service/job-offers/respond?wmUniqueId=${wmUniqueId}&offerId=${offerId}&offerStatus=${offerStatus}`, { headers });
  }

  payForJob(offerId: number, milestoneId: number, transactionPin: number, description: string){
    let params = new HttpParams()
    .set('offerId', offerId.toString())
    .set('milestoneId', milestoneId.toString())
    .set('transactionPin', transactionPin.toString())
    .set('description', description);

    return this.http.post(`${environment.apiUrl}/service/contract-offer/initiate-payment`, params)
    // return this.http.post(`${environment.apiUrl}/service/contract-offer/initiate-payment?offerId=${offerId}&milestoneId=${milestoneId}&transactionPin=${transactionPin}&description=${description}`)
    // return this.http.post(`${environment.apiUrl}/service/contract-offer/initiate-payment?offerId=${offerId}&milestoneId=${milestoneId}&transactionPin=${transactionPin}&description=${description}`, params)
  }

  uploadVerificationDocs(data: FormData): Observable<any> {
    // You can set custom headers if needed
    const headers = new HttpHeaders();

    return this.http.post(`${environment.apiUrl}/private/service/provider-settings/verification`, data, { headers });
  }
  contactUs(info: any): Observable<any> {
    return this.http.post<any>(`${environment.miscUrl}/contact-us`, info);
  }
  // Buyer And Sellers Notifications
  getBuyersNotifications(wmUniqueId :string): Observable<any>{
    return this.http.get<any>(`${environment.notificationUrl}/notification/user?wmUniqueId=${wmUniqueId}&userType=BUYER&sourceApp=SERVICE_MARKETPLACE_APP`);
  }
  getSellersNotifications(wmUniqueId :string): Observable<any>{
    return this.http.get<any>(`${environment.notificationUrl}/notification/user?wmUniqueId=${wmUniqueId}&userType=SELLER&sourceApp=SERVICE_MARKETPLACE_APP`);
  }
  readNotifications(Id : number, wmUniqueId: string): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.notificationUrl}/notification/read/${Id}?wmUniqueId=${wmUniqueId}`, {headers});
  }
  submitAnOrder(userId: string, orderId : number): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.apiUrl}/service-orders/submit?wmUniqueId=${userId}&orderId=${orderId}`, {headers});
  }
  reviewAnOrder(userId: string, orderId : number): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.apiUrl}/private/service-orders/review?wmUniqueId=${userId}&orderId=${orderId}`, {headers});
  }
  approveAnOrder(userId: string, orderId : number): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.apiUrl}/private/service-orders/approve?wmUniqueId=${userId}&orderId=${orderId}`, {headers});
  }
  submitMilestone(milestone : any): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.apiUrl}/service/contract-offer/milestone`,milestone, {headers});
  }
  activateMilestone(milestone : any): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.post<any>(`${environment.apiUrl}/service/contract-offer/milestone/activate?wmUniqueId=${milestone.wmUniqueId}&milestoneId=${milestone.milestoneId}`, {headers});
  }
  editMilestone(milestone : any): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.put<any>(`${environment.apiUrl}/service/contract-offer/milestone/activate`,milestone, {headers});
  }
  deleteMilestone(id: number): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete<any>(`${environment.apiUrl}/service/contract-offer/milestone/${id}`, { headers })
  }
  submitJobForApproval(order : any): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    let url = `${environment.apiUrl}/service/job-offers/submit?wmUniqueId=${order.wmUniqueId}&offerId=${order.offerId}`;
    // Conditionally add milestoneId if provided
    if (order.milestoneId) {
      url += `&milestoneId=${order.milestoneId}`;
    }
    return this.http.post<any>(url, {headers});
  }
  approveJobOrder(order : any): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    let url = `${environment.apiUrl}/service/job-offers/approve?wmUniqueId=${order.wmUniqueId}&offerId=${order.offerId}`;
    // Conditionally add milestoneId if provided
    if (order.milestoneId) {
      url += `&milestoneId=${order.milestoneId}`;
    }
    return this.http.post<any>(url, {headers});
  }

  reviewOrder(order: any): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    // Create URLSearchParams object to build the query string
    let params = new URLSearchParams();
    params.set('wmUniqueId', order.wmUniqueId);
    params.set('offerId', order.offerId);

    // Conditionally add milestoneId if provided
    if (order.milestoneId) {
      params.set('milestoneId', order.milestoneId);
    }

    // Build the full URL
    const url = `${environment.apiUrl}/service/contract-offer/review?${params.toString()}`;

    return this.http.post<any>(url, {}, { headers });
  }
  getActiveMilestone(offerId : number): Observable<any>{
    const headers = new HttpHeaders ({'Content-Type':'application/json'});
    return this.http.get<any>(`${environment.apiUrl}/service/contract-offer/active-milestone/${offerId}`, {headers});
  }

  getUnreadMessages(userId: any): Observable<any> {
    return this.http.get<any>(`https://ca.thewealthmarket.com/api/unread-messages-count/${userId}`);
  }
  //get all messagse
  getUsersMessage(): Observable<any> {
    return this.http.get<any>(`https://ca.thewealthmarket.com/api/messages`);
  }

  //Get users in message database
  getUsersList(): Observable<any> {
    return this.http.get<any>(`https://ca.thewealthmarket.com/api/users`);
  }
}

