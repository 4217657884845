import { AuthenticationService } from './../../authentication/authentication.service';
import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { Event, Router, NavigationStart } from '@angular/router';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { FirebaseMessagingService } from 'src/app/shared/services/firebase-messaging.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ChatListContact } from 'src/app/shared/interfaces/messages';
import { CurrentUser } from 'src/app/shared/interfaces/currentUser';
import { map, tap } from 'rxjs/operators';
import { Provider } from 'src/app/shared/interfaces/serviceProvider';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
import { Breakpoints } from '@angular/cdk/layout';
import { AppComponent } from 'src/app/app.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { IUserClaims } from 'src/app/shared/interfaces/userObject';
import { CommonServiceService } from 'src/app/common-service.service';
import { INotifications } from 'src/app/shared/interfaces/notifications';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  authenticated$ = new BehaviorSubject<boolean>(false); // Initialize with a default value
  //this is the guy watching that is there a token? If there is, cacheProvider.
  base = '';
  splitVal;
  page = '';
  chatNotificationBell$: Observable<ChatListContact | []>;
  serviceProvider: Provider = JSON.parse(
    localStorage.getItem('serviceProvider')
  );
  currentUser:  IUserClaims = JSON.parse(localStorage.getItem('userObject'));

  idOfCurrentUser: string;
  customerName: string;
  userImg$: Observable<string>;
  providerName: string;
  providerImage: string;
  sub: Subscription;
  sub_screen: Subscription;
  screenSize = this.screenSizeService.screenSize;
  largeScreen: boolean;
  sellersNotifications: INotifications[] = [];
  buyersNotifications: INotifications[] = [];
  unreadNotificationsSeller: number = 0;
  unreadNotificationsBuyer: number = 0;
  readBuyersNotifications: boolean;
  readSellersNotifications: boolean;
  adjustedDate: any;
  unreadMessagesCount: number;
  allMessageUsers:any[] = [];
  currentUrl: string = '';
  private intervalId: any;

  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    private communicationService: CommunicationService,
    private authService: AuthenticationService,
    private fireBase: FirebaseMessagingService,
    private screenSizeService: ScreenSizeService,
    private appComponent: AppComponent,
    private oauthService: OAuthService,
    private commonService: CommonServiceService,
  ) { }
  ngAfterViewChecked(): void {
    this.serviceProvider = JSON.parse(localStorage.getItem('serviceProvider'));
    //handle user details
    if (this.currentUser) {
      this.customerName =
        this.currentUser.name;  // this.userImg = 'assets/img/customer/user-01.jpg';
    }
    //handle provider details
    if (this.serviceProvider) {
      this.providerName = this.serviceProvider.businessName;
      this.providerImage =
        this.serviceProvider.imageUrl || 'assets/img/customer/user-01.jpg';
    }
  }

  ngOnInit(): void {
    this.getBuyersNotifications(this.currentUser['wm-unique-id'])
    this.getSellersNotifications(this.currentUser['wm-unique-id'])

    // Makes api call every minute to constantly update the notifications
    this.intervalId = setInterval(() => {
      this.getSellersNotifications(this.currentUser['wm-unique-id']);
    }, 60000);

    this.intervalId = setInterval(() => {
      this.getBuyersNotifications(this.currentUser['wm-unique-id']);
    }, 60000);

    //watches the oauthService's events for when there's a token. Once there is, it caches the provider.
    this.oauthService.events.subscribe(
      (event) => {
        //this was a const so it wasn't being updated. It needs to update in realtime and the "if" must become "once x happens..."
        // OR turn authenticated$ to an observable
        if (this.oauthService.hasValidAccessToken()){//value isnt reset to true till you click login again
          // this.authenticated$.next(true); //behaviour subject authenticated$ emits true this didn't change in real time.
          // alert("authenticated$ should have emitted as true by now!")
          this.authService.cacheServicerovider(); //this ran and since it calls observable service
                                                  //provider, "Become a Service Seller" disappeared real time.
          this.getDp();
        }
      }
    );

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
        // alert(event.url.split('/'))
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
    this.sub_screen = this.screenSize
    .observe([Breakpoints.Large])
    .subscribe((screen) => (this.largeScreen = screen.matches));

    // this.getUnreadMessages();
    // this.getUsers();
    this.getAllUsers();
    this.currentUrl = this.router.url;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub_screen.unsubscribe();
  }

  getDp(): Observable<string> {
    this.commonService.getProfilepic(this.currentUser['wm-unique-id']).subscribe(
      (res) => {console.log("profile pic: ", res)},
      (error) => {
        this.userImg$ = error.text || 'assets/img/provider/provider-01.jpg';
        console.log("error with profile pic: ", error);
      }
      )
      return this.userImg$;
  }
  getUnreadMessages() {
    this.commonService.getUnreadMessages(this.currentUser['wm-unique-id']).subscribe(
      (res) => {
      this.unreadMessagesCount = res.unreadMessagesCount
      console.log('unread message count', this.unreadMessagesCount)

      },
      (error) => {
        console.log("error getting unread messages count:", error);
      }
      )
  }
  getUsers() {
    this.commonService.getUsersList().subscribe(
      (res) => {
      this.allMessageUsers = res
      console.log('Messaging users', this.unreadMessagesCount)

      },
      (error) => {
        console.log("error getting unread messages count:", error);
      }
    )
  }

//Get all chat users
  getAllUsers(): void {
    this.commonService.getUsersList().subscribe(users => {
      // Step 2: Filter users
      const filteredUsers = users.filter(user => this.currentUser['wm-unique-id'] === user.userId);
      console.log('Filtered users', filteredUsers);

      if (filteredUsers.length > 0) {
        // Step 3: Extract the ids of all filtered users
        const userIds = filteredUsers.map(user => user.id);

        console.log('Users idsss', userIds);

        // Step 5: Fetch unread messages for each id
        this.getUnreadMessagesForIds(userIds);
      }
    });
  }

  getUnreadMessagesForIds(userIds: number[]): void {
    // console.log('Users id2', userIds);
    let totalUnread = 0;

    // get unread messages by user ID
    userIds.forEach(id => {
      this.commonService.getUnreadMessages(id).subscribe
      (response => {
          totalUnread += response.unreadMessagesCount;
          this.unreadMessagesCount = totalUnread;
          // console.log('total unread', this.unreadMessagesCount);
        });
    });
  }

  getChatNotification() {
    // console.log('service user data ', this.authService.getCurrentUserValue());
    this.authService.currentUser$.subscribe((currentUser: CurrentUser) => {
      // if user is logged in (using emition of logged in user's cached details)
      if (currentUser?.user.uniqueId !== undefined) {
        this.idOfCurrentUser = currentUser.user.uniqueId;
        this.chatNotificationBell$ = this.fireBase
          .unreadChatMessage(currentUser?.user.uniqueId)
          .pipe(
            map((chatListContact: ChatListContact[]) => {
              return chatListContact[0];
            })
            // tap((chatListContact) => console.log('chatNotificationBell ', chatListContact))
          );
      } else {
        this.chatNotificationBell$ = of([]);
      }
      // console.log('currentUser$', currentUser);
    });
  }

  login(){
    this.oauthService.initImplicitFlow();
  }

  logout() {
    localStorage.clear();
    this.appComponent.logout();
    // this.authenticationService.logout();
    // this.authService.currentUserSubject.next(null);
    // used to check if the user is being logged
    // this.authService.currentUserSubject.subscribe((val) =>
    //   console.log('Observable value ', val)
    // );
    this.router.navigate(['../index']);
  }
    get token(){
    let claims:any = this.oauthService.getIdentityClaims();
    return claims? claims : null;
  }
  // toggle(){
  //   this.authenticated$ = !this.authenticated$;
  // }

  // Get seller' notifications
  getBuyersNotifications(wmUniqueId: string){
    this.commonService.getBuyersNotifications(wmUniqueId).subscribe({
      next: (res) => {
        this.buyersNotifications = res.data.map(notification => {
          notification.createdDate = new Date(new Date(notification.createdDate).getTime() + 3600000);
          notification.displayDate = this.getDisplayDate(notification.createdDate);
          return notification;
        });
        console.log("Buyers notifications",this.buyersNotifications);
        this.getUnreadNotificationsB()
      },
      error: (error) => {console.log("Error getting buyer's notification:", error)}
    })

  }
  // Get buyer's notifications
  getSellersNotifications(wmUniqueId: string){
    this.commonService.getSellersNotifications(wmUniqueId).subscribe({
      next: (res) => {
        this.sellersNotifications = res.data.map(notification => {
          notification.createdDate = new Date(new Date(notification.createdDate).getTime() + 3600000);
          notification.displayDate = this.getDisplayDate(notification.createdDate);
          return notification;
        });
        console.log("Sellers notifications",this.sellersNotifications);
        this.getUnreadNotificationsB()
      },
      error: (error) => {console.log("Error getting sellers's notification:", error)}
    })
  }

  handleNotificationClick(notification: any): void {
    if (notification.eventType === 'PAYMENT_RELEASED') {
      this.router.navigate(['/providers/dashboard']);
    } else if (notification.eventType === 'SERVICE_ORDER_PAYMENT' || notification.eventType === 'SERVICE_ORDER_APPROVAL') {
      this.router.navigate(['/providers/booking-list']);
    } else if (notification.eventType === 'OFFER_REQUEST') {
      this.router.navigate(['/providers/job-offers']);
    } else if (notification.eventType === 'OFFER_REQUEST') {
      this.router.navigate(['/providers/job-offers']);
    } else if (notification.eventType === 'JOB_INVITATION_REQUEST') {
      this.router.navigate(['/providers/job-invitation']);
    } else if (notification.eventType === 'JOB_ASSIGNED') {
      this.router.navigate(['/providers/job-invitation']);
    }
  }
  handleCustomerNotificationClick(notification: any): void {
    if (notification.eventType === 'PAYMENT_RELEASED') {
      this.router.navigate(['/customers/dashboard']);
    } else if (notification.eventType === 'SERVICE_ORDER_PAYMENT') {
      this.router.navigate(['/customers/my-bookings']);
    } else if (notification.eventType === 'OFFER_REQUEST' || notification.eventType === 'OFFER_REQUEST_STATUS') {
      this.router.navigate(['/customers/job-offer']);
    } else if (notification.eventType === 'JOB_APPLICATION_REQUEST' || notification.eventType === 'OFFER_REQUEST_STATUS' || notification.eventType === 'SERVICE_ORDER_APPROVAL_REQUEST') {
      this.router.navigate(['/customers/job-application']);
    } else if (notification.eventType === 'CUSTOM_SERVICE_REQUEST') {
      this.router.navigate(['/customers/my-bookings']);
    } else if (notification.eventType === 'JOB_INVITATION_REQUEST_STATUS') {
      this.router.navigate(['/customers/my-bookings']);
    }
  }


  // Buyer and Seller's unread notifications
  getUnreadNotifications(): void {
    this.unreadNotificationsSeller= this.sellersNotifications.filter(notification => !notification.read).length;
  }
  getUnreadNotificationsB(): void {
    this.unreadNotificationsBuyer = this.buyersNotifications.filter(notification => !notification.read).length;
  }

  // Read buyer notifications
  readNotificationsB(Id: number, wmUniqueId: string){
    this.commonService.readNotifications(Id, wmUniqueId).subscribe({
      next: (res) => {
        this.readBuyersNotifications = res;
      },
      error: (error) => {console.log("Error reading notification:", error)}
    })
  }
  // Read sellers notifications
  readNotificationsS(Id: number, wmUniqueId: string){
    this.commonService.readNotifications(Id, wmUniqueId).subscribe({
      next: (res) => {
        this.readSellersNotifications = res;
      },
      error: (error) => {console.log("Error reading notification:", error)}
    })
  }
  // Clear all notifications seller
  readAllSellersNotifications() {
    const wmUniqueId = this.currentUser['wm-unique-id'];
    if (this.sellersNotifications && this.sellersNotifications.length) {
      this.sellersNotifications.forEach(notification => {
        this.readNotificationsS(notification.id, wmUniqueId);
        this.unreadNotificationsBuyer === 0
      });
    }
  }
  // Clear all notifications buyer
  readAllBuyersNotifications() {
    const wmUniqueId = this.currentUser['wm-unique-id'];
    if (this.buyersNotifications && this.buyersNotifications.length) {
      this.buyersNotifications.forEach(notification => {
        this.readNotificationsB(notification.id, wmUniqueId);
        this.getUnreadNotifications()
        this.unreadNotificationsSeller === 0
      });
    }
  }
  getDisplayDate(date: Date): string {
    const now = new Date();
    const diffInSecs = now.getTime() - new Date(date).getTime();
    const diffInDays = Math.floor(diffInSecs / (1000 * 60 * 60 * 24));

    if (diffInDays <= 7) {
      return new Date(date).toLocaleString('en-NG', {
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
    } else {
      return `${diffInDays} days ago`;
    }
  }

  goToSettings(){
    this.router.navigate(['/providers/profile']);
  }

}
