import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseResponse } from '../shared/interfaces/response';
import { ResponseType } from 'src/interfaces/response';
import { User } from '../shared/interfaces/user';
import { ServiceProvider } from '../shared/interfaces/serviceProvider';
import { CommonServiceService } from '../common-service.service';
import { CurrentUser } from '../shared/interfaces/currentUser';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from 'src/app/sso.config';

const CACHE_KEY = 'serviceProvider';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: User;
  respData: any;
  public currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUser$: Observable<CurrentUser>;
  public serviceProvider$: Observable<ServiceProvider>;


  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private http: HttpClient,
    private commonService: CommonServiceService,
    private oauthService: OAuthService
  ) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser$ = this.currentUserSubject.asObservable();
  }
  public setcurrentUserValue(data): void { //login event resp runs this
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.currentUserSubject.next(data);
    this.cacheServicerovider();

  }

  public getCurrentUserValue(): CurrentUser {
    return this.currentUserSubject.value;
  }




  login(email, password): Observable<ResponseType> {
    const auth = { usernameEmail: email, password: password };
    return this.http
      .post<ResponseType>(`${environment.apiUrl}/auth/login`, auth)
      .pipe(
        map((resp) => {
          this.respData = resp;
          if (this.respData.statusCode === 200) {
            this.setcurrentUserValue(this.respData.data);
            // this.cacheServicerovider();
          }
          return resp;
        })
      );
  }
  logout() {
    // localStorage.removeItem('currentUser');
    localStorage.clear()
    this.currentUserSubject.next(null);
    this.currentUserSubject.subscribe(val => console.log('Observable value ', val));
    this.router.navigate(['../index']);
  }

  cacheServicerovider() {
    const currentUserObject = JSON.parse(localStorage.getItem('userObject'));

    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let serviceProvider = JSON.parse(localStorage.getItem('serviceProvider'));
    if (!serviceProvider && currentUserObject) {
      //they got service provider by user id if there was none. Do same with wmUniqueId
      this.serviceProvider$ = this.commonService.getServiceProvider(currentUserObject['wm-unique-id']);
      this.serviceProvider$.subscribe((next) => {
        if (next.statusCode === 200) {
          localStorage[CACHE_KEY] = JSON.stringify(next.data[0]) || null;
        }
      });
    }
  }
  cacheServicerovider2() {
    console.log("upload img has indeed updated server's provider object");
    let currentUserObject = JSON.parse(localStorage.getItem('userObject'));
    console.log('user wmId to be used in regetting the provider: ' , currentUserObject['wm-unique-id'])
      this.serviceProvider$ = this.commonService.getServiceProvider(currentUserObject['wm-unique-id']);
      this.serviceProvider$.subscribe((next) => {
        if (next.statusCode === 200) {
          console.log('re-gotten provider so new image comes into cache: ', next.data);
          localStorage[CACHE_KEY] = JSON.stringify(next.data[0]); //perhaps this isn't updating it.
          // alert('DP updated!');
        }
      });
      return null;
  }

  sendCustomVerificationCode(
    email: string,
    type: string
  ): Observable<BaseResponse> {
    //set the request parameters
    let params = new HttpParams().set('email', email).set('type', type);

    return this.http
      .post<BaseResponse>(
        `${environment.apiUrl}/user/send-custom-verification-code`,
        '',
        { params }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  verify2FAOnLogin(params: any): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}/auth/verify-2fa-auth`,
      params
    );
  }
}
