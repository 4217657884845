<header class="header">
  <nav class="navbar navbar-expand-lg header-nav">
    <div class="navbar-header">
      <a id="mobile_btn" href="javascript:void(0);">
        <span class="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>
      <a *ngIf="largeScreen" [routerLink]="'/'" class="navbar-brand logo d-flex">
        <img
          src="https://forum.thewealthmarket.com/uploads/db2453/original/1X/5a050172eab982b05a8654220c1a4b1380ce2fa3.jpeg"
          class="img-fluid" alt="Logo" />
        <h4 class="text-white ml-2">
          WM Service <span style="color: #ffcd38">MarketPlace</span>
        </h4>
      </a>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-header">
        <a [routerLink]="'/'" class="menu-logo">
          <img
            src="https://forum.thewealthmarket.com/uploads/db2453/original/1X/5a050172eab982b05a8654220c1a4b1380ce2fa3.jpeg"
            class="img-fluid inside-logo" alt="Logo" />
        </a>

        <a id="menu_close" class="menu-close" href="javascript:void(0);">
          <i class="fas fa-times"></i>
        </a>
      </div>

      <ul class="main-nav">
        <li [class.active]="base === '' || base === 'index' || page === 'register'">
          <a [routerLink]="'/'">Home</a>
        </li>
        <li *ngIf=" token &&
            (base === '' ||
              base === 'index' ||
              base === 'categories' ||
              base === 'pages' ||
              base === 'services' ||
              base === 'providers' ||
              base === 'customers' ||
              base === 'become a service provider' ||
              base === 'post a job') &&
            serviceProvider
          " class="has-submenu" [routerLinkActive]="'active'">
          <a href="javascript:void(0)">Sellers<i class="fas fa-chevron-down"></i></a>
          <ul class="submenu">
            <li><a [routerLink]="'/providers/dashboard'">Dashboard</a></li>
            <li><a [routerLink]="'/providers/services'">My Services</a></li>
            <li>
              <a [routerLink]="'/providers/booking-list'">Services Sold</a>
            </li>
            <li>
              <a [routerLink]="'/providers/job-offers'">Job Offers</a>
            </li>
            <!-- <li>
              <a [routerLink]="'/providers/list-service'">List Service</a>
            </li> -->
            <li>
              <a [routerLink]="'/providers/profile'"> My Profile</a>
            </li>
            <li>
              <a [routerLink]="'/providers/job-invitation'">Job Invitation List</a>
            </li>
            <li>
              <a [routerLink]="'/providers/reviews'">Service Reviews</a>
            </li>
            <li>
              <a [routerLink]="'/providers/my-review'">My Reviews</a>
            </li>
            <li>
              <a [routerLink]="'/providers/refund-request'">Refund Request</a>
            </li>
            <li>
              <a [routerLink]="'/providers/job-application'">Job Applications</a>
            </li>
          </ul>
        </li>
        <li *ngIf="
            token &&
            (base === '' ||
              base === '*' ||
              base === 'index' ||
              base === 'categories' ||
              base === 'pages' ||
              base === 'services' ||
              base === 'providers' ||
              base === 'customers' ||
              base === 'become a service provider' ||
              base === 'post a job')
          " class="has-submenu" [routerLinkActive]="'active'">
          <a href="javascript:void(0)">Buyers <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu" id="submenu">
            <li><a [routerLink]="'/customers/dashboard'">Dashboard</a></li>
            <li>
            <!-- <li><a [routerLink]="'/customers/services'">My Services</a></li>
            <li> -->
              <a [routerLink]="'/customers/my-bookings'">Services Purchased</a>
            </li>
            <li><a [routerLink]="'/customers/job-offer'">Job Offers</a></li>
            <li>
            <li>
              <a [routerLink]="'/customers/jobs/jobs-application-details'">Jobs Posted</a>
            </li>
            <li><a [routerLink]="'/customers/reviews'">Service Reviews</a></li>
            <li><a [routerLink]="'/customers/providers-reviews'">Providers Reviews</a></li>
            <li><a [routerLink]="'/customers/refund-request'">Refund Request</a></li>
            <li>
            <li><a [routerLink]="'/customers/job-application'">Job Applications</a></li>
          </ul>
        </li>
        <li *ngIf="
            base === '' ||
            base === 'index' ||
            base === 'categories' ||
            base === 'pages' ||
            base === 'services' ||
            base === 'providers' ||
            base === 'customers' ||
            base === 'become a service provider' ||
            base === 'post a job'
          " class="has-submenu" [routerLinkActive]="'active'">
          <a [routerLink]="'/services/listings'" routerLinkActive="active">Book a service</a>
        </li>
        <li *ngIf="
            base === '' ||
            base === 'index' ||
            base === 'categories' ||
            base === 'pages' ||
            base === 'services' ||
            base === 'providers' ||
            base === 'customers' ||
            base === 'become a service provider' ||
            base === 'post a job'
          " class="has-submenu" [routerLinkActive]="'active'">
          <a [routerLink]="'/customers/all-jobs'" routerLinkActive="active">Find A Job</a>
        </li>
        <li *ngIf="
            base === '' ||
            base === 'index' ||
            base === 'categories' ||
            base === 'pages' ||
            base === 'services' ||
            base === 'customers' ||
            base === 'become a service provider' ||
            base === 'post a job'
          " class="has-submenu" [routerLinkActive]="'active'">
          <a *ngIf="!serviceProvider" [routerLink]="'/providers/register'" routerLinkActive="active">Become a Service Seller</a>
        </li>
        <!-- <li *ngIf="base === '' || base === 'index' || base === 'categories' || base === 'pages' || base === 'services' || base === 'apply for a job'" class="has-submenu">
          <a href="javascript:void(0)">Apply for a Job<i class="fas fa-chevron-down"></i></a>
          <ul class="submenu">
            <li><a [routerLink]="'/services/jobs/post-jobs'">Apply for a Job</a></li>
            <li><a [routerLink]="'/services/jobs/jobs-application-details'">Job Application Details</a></li>
          </ul>
        </li> -->
        <!-- <li *ngIf="authenticated && (base === '' || base === 'index' || base === 'categories' || base === 'pages' || base === 'services' || base === 'providers' || base === 'customers' || base === 'become a service provider' || base === 'post a job')" class="has-submenu">
          <a href="javascript:void(0)">Post a Job<i class="fas fa-chevron-down"></i></a>
          <ul class="submenu">
            <li><a [routerLink]="'/customers/jobs/new'">Post a Job</a></li>
            <li><a [routerLink]="'/services/jobs/post-jobs-details'">Post Job Details</a></li>
          </ul>
        </li> -->
      </ul>
    </div>

                              <!-- CUSTOMERS  -->
    <ul class="nav header-navbar-rht" *ngIf="token && base === 'customers'">


      <!-- Notifications -->
      <li class="nav-item dropdown logged-item">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-bell"></i>
          <span class="badge badge-pill bg-yellow" *ngIf="unreadNotificationsBuyer > 0">{{unreadNotificationsBuyer}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)"  class="clear-noti" *ngIf="unreadNotificationsBuyer > 0" (click)="readAllBuyersNotifications()"> Clear All </a>
          </div>
          <div class="noti-content">
            <ul class="notification-list" *ngFor="let notification of buyersNotifications" >
              <li class="notification-message" [ngClass]="{'unread-notification': !notification.read}">
                <a (click)="handleCustomerNotificationClick(notification)">
                  <div class="media">
                    <span class="avatar avatar-sm">
                      <img class="avatar-img rounded-circle img-fluid" alt="User Image"
                        src="assets/img/provider/provider-01.jpg" />
                    </span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">{{notification.message}}</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">{{ notification.displayDate }}</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <ul>
              <li *ngIf="buyersNotifications.length == 0" class="notification-message">
                <div class="p-5">
                  <p>You have no notifications yet</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a [routerLink]="'/pages/notifications'">View all Notifications</a>
          </div>
        </div>
      </li>
      <!-- /Notifications -->

      <!-- chat -->
      <li class="nav-item logged-item">
        <a [routerLink]="'/pages/chat'" [queryParams]="{ type: 'buyer' }" class="nav-link">
          <i class="fa fa-comments"></i>
          <span *ngIf="unreadMessagesCount && unreadMessagesCount > 0 " class="badge badge-pill bg-yellow">{{ unreadMessagesCount }}</span>
          <!-- <span class="badge badge-pill bg-yellow">115</span> -->
        </a>
      </li>
      <!-- /chat -->

      <li class="nav-item dropdown has-arrow logged-item">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
          <span class="user-img">
            <img class="rounded-circle img-fluid" [src]="userImg" alt="" width="31" />
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" style="height: 70vh; overflow-y:scroll;">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img class="avatar-img rounded-circle img-fluid" [src]="userImg" alt="" />
            </div>
            <div class="user-text">
              <h6>{{ customerName }}</h6>
              <p class="text-muted mb-0">User</p>
            </div>
          </div>
          <a class="dropdown-item" [routerLink]="'/customers/dashboard'">Dashboard</a>
          <a class="dropdown-item" [routerLink]="'/customers/my-bookings'">Services Purchased</a>
          <!-- <a class="dropdown-item" [routerLink]="'/customers/profile-settings'">Profile Settings</a> -->
          <!-- <a class="dropdown-item" [routerLink]="'/customers/wallet'">Wallet</a> -->
          <a class="dropdown-item" [routerLink]="'/customers/job-offer'">Job Offers Sent</a>
          <a class="dropdown-item" [routerLink]="'/customers/jobs/jobs-application-details'">Jobs Posted</a>
          <a class="dropdown-item" [routerLink]="'/customers/reviews'">Service You've Reviewed</a>
          <a class="dropdown-item" [routerLink]="'/customers/providers-reviews'">Providers You've Reviewed</a>
          <a class="dropdown-item" [routerLink]="'/customers/refund-request'">Refund Request</a>
          <a class="dropdown-item" [routerLink]="'/customers/job-application'">Job Applications Received</a>
          <!-- <a class="dropdown-item" [routerLink]="'/customers/reviews'">Reviews</a> -->
          <a class="dropdown-item" [routerLink]="'/index'" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
                              <!-- PROVIDERS  -->
    <ul class="nav header-navbar-rht" *ngIf="token && base === 'providers'">
      <li class="nav-item desc-list">
        <a *ngIf="
            page !== undefined && page !== 'list-service' && page !== 'register'
          " [routerLink]="'/providers/list-a-service'" class="nav-link header-login">
          <i class="fas fa-plus-circle mr-1"></i>
          <span>List a Service</span>
        </a>
      </li>

      <!-- Provider's Notifications -->
      <li class="nav-item dropdown logged-item">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-bell"></i>
          <span class="badge badge-pill bg-yellow" *ngIf="unreadNotificationsSeller > 0">{{unreadNotificationsSeller}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)"  class="clear-noti" *ngIf="unreadNotificationsSeller > 0" (click)="readAllSellersNotifications()"> Clear All </a>
          </div>
          <div class="noti-content">
            <ul class="notification-list" *ngFor="let notification of sellersNotifications" >
              <li class="notification-message" [ngClass]="{'unread-notification': !notification.read}">
                <a (click)="handleNotificationClick(notification)" style="cursor: pointer;">
                  <div class="media">
                    <span class="avatar avatar-sm">
                      <img class="avatar-img rounded-circle img-fluid" alt="User Image"
                        src="assets/img/provider/provider-01.jpg" />
                    </span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">{{notification.message}}</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">{{ notification.createdDate | date: 'EEEE, h:mm a' }}</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">{{ notification.displayDate }}</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <ul>
              <li *ngIf="sellersNotifications.length == 0" class="notification-message">
                <div class="p-5">
                  <p>You have no notifications yet</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a [routerLink]="'/pages/notifications'">View all Notifications</a>
          </div>
        </div>
      </li>
      <!-- /Provider's Notifications -->

      <!-- Provider's chat -->
      <li class="nav-item logged-item dropdown">
        <a [routerLink]="'/pages/chat'" [queryParams]="{ type: 'seller' }" class="nav-link">
          <i class="fa fa-comments"></i>
          <span *ngIf="unreadMessagesCount && unreadMessagesCount > 0 " class="badge badge-pill bg-yellow">{{ unreadMessagesCount }}</span>
        </a>
      </li>
      <!-- /Provider's chat -->

      <!-- providers Menu -->
      <li *ngIf="page !== 'register'" class="nav-item dropdown has-arrow logged-item">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
          <span class="user-img">
            <img class="rounded-circle img-fluid" [src]="providerImage" alt="" width="31" />
          </span>
        </a>

        <div class="dropdown-menu dropdown-menu-right" style="height: 70vh; overflow-y:scroll;">
          <div class="user-header">
            <div class="avatar avatar-sm" (click)="goToSettings()" style="cursor: pointer;">
              <img class="avatar-img rounded-circle img-fluid" [src]="providerImage"  alt="" />
            </div>
            <div class="user-text">
              <h6 class="text-truncate">{{ providerName }}</h6>
              <p class="text-muted mb-0">Provider</p>
            </div>
          </div>

          <a class="dropdown-item" [routerLink]="'/providers/dashboard'">Dashboard</a>
          <a class="dropdown-item" [routerLink]="'/providers/services'">My Services</a>
          <a class="dropdown-item" [routerLink]="'/providers/booking-list'">Services Sold</a>
          <a class="dropdown-item" [routerLink]="'/providers/profile'">My Profile</a>
          <a class="dropdown-item" [routerLink]="'/providers/list-a-service'">List Service</a>
          <a class="dropdown-item" [routerLink]="'/providers/job-offers'">Job Offers Received</a>
          <a class="dropdown-item" [routerLink]="'/providers/job-invitation'">Job Invitations Received</a>
          <a class="dropdown-item" [routerLink]="'/providers/reviews'">Service Reviews</a>
          <a class="dropdown-item" [routerLink]="'/providers/my-reviews'">My Reviews</a>
          <a class="dropdown-item" [routerLink]="'/providers/refund-request'">Refund Requests</a>
          <a class="dropdown-item" [routerLink]="'/providers/job-application'">Job You Applied For</a>
          <!-- <a class="dropdown-item" [routerLink]="'/providers/wallet'">Wallet</a>
            <a class="dropdown-item" [routerLink]="'/providers/availability'">Availability</a>
          <a class="dropdown-item" [routerLink]="'/providers/subscription'">Subscription</a>
          <a class="dropdown-item" [routerLink]="'/pages/chat'">Chat</a> -->
          <a class="dropdown-item" [routerLink]="'/index'" (click)="logout()">Logout</a>
        </div>
      </li>
      <!-- /providers Menu -->
    </ul>

    <!-- logout button -->
    <ul *ngIf="
        base === '' ||
        base === 'index' ||
        base === 'categories' ||
        base === 'pages' ||
        base === 'services' ||
        page === 'register'
      " class="nav header-navbar-rht">

      <li class="nav-item" *ngIf="!token">
        <a (click)="login()" style="cursor: pointer;" class="nav-link header-login">Login</a>
      </li>
      <li class="nav-item" *ngIf="token">
        <a (click)="logout()" routerLink="/index" class="nav-link header-login">Logout</a>
      </li>
      <!-- <li class="nav-item">
                <a (click)="toggle()" routerLink="/index" class="nav-link header-login">Toggle</a>
            </li> -->
    </ul>
  </nav>
</header>
