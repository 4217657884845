<head>
  <meta name="robots" content="noindex, nofollow">
</head>

<div class="main-wrapper">
	<div *ngIf="base != 'authentication'">
		<app-header [hidden]="!show" [authenticated$]="authenticated"></app-header>
	</div>
	<div *ngIf="base == 'authentication'">
		<app-login-header></app-login-header>
	</div>
	<router-outlet></router-outlet>
	<app-footer *ngIf="loadFooter" [hidden]="hideFooter"></app-footer>
</div>
<!-- Add service offer Modal -->
<div class="modal fade custom-modal" id="add_service_offer">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Service Offer</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <form>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Service Offered</label>
                        <input type="text" class="form-control" value="">
                    </div>
                    <div class="submit-section text-center">
                        <button type="submit" class="btn btn-primary submit-btn mr-1" data-dismiss="modal" aria-label="Close">Submit</button>
                        <button type="button" class="btn btn-secondary submit-btn" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- /Add service offer Modal -->

<!-- Edit service offer Modal -->
<div class="modal fade custom-modal" id="edit_service_offer">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Edit Service Offer</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <form>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Service Offered</label>
                        <input type="text" class="form-control" value="Car wash">
                    </div>
                    <div class="submit-section text-center">
                        <button type="submit" class="btn btn-primary submit-btn mr-1" data-dismiss="modal" aria-label="Close">Submit</button>
                        <button type="button" class="btn btn-secondary submit-btn" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- /Edit service offer Modal -->
<!-- Provider Register Modal -->
<div class="modal account-modal fade multi-step" id="provider-register" data-keyboard="false" data-backdrop="static">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header p-0 border-0">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-header">
					<h3>Join as a Provider</h3>
				</div>

				<!-- Register Form -->
				<form>
					<div class="form-group form-focus">
						<label class="focus-label">Name</label>
						<input type="text" class="form-control" placeholder="johndoe@exapmle.com">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Mobile Number</label>
						<input type="text" class="form-control" placeholder="986 452 1236">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Password</label>
						<input type="password" class="form-control" placeholder="********">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Confirm Password</label>
						<input type="password" class="form-control" placeholder="********">
					</div>
					<div class="text-right">
						<a class="forgot-link" href="javascript:void(0);">Already have an account?</a>
					</div>
					<button class="btn btn-primary btn-block btn-lg login-btn" data-dismiss="modal" aria-label="Close" type="submit">Signup</button>
					<div class="login-or">
						<span class="or-line"></span>
						<span class="span-or">or</span>
					</div>
					<div class="row form-row social-login">
						<div class="col-6">
							<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f mr-1"></i> Login</a>
						</div>
						<div class="col-6">
							<a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google mr-1"></i> Login</a>
						</div>
					</div>
				</form>
				<!-- /Register Form -->

			</div>
		</div>
	</div>
</div>
<!-- /Provider Register Modal -->

<!-- User Register Modal -->
<div class="modal account-modal fade multi-step" id="user-register" data-keyboard="false" data-backdrop="static">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header p-0 border-0">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-header">
					<h3>Join as a User</h3>
				</div>

				<!-- Register Form -->
				<form>
					<div class="form-group form-focus">
						<label class="focus-label">Email</label>
						<input type="email" class="form-control" name="email"  autofocus="" placeholder="johndoe@exapmle.com">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Mobile Number</label>
						<input type="number" class="form-control" name="mobile"  placeholder="986 452 1236">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Password</label>
						<input type="password" class="form-control" name="password"  placeholder="********">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Confirm Password</label>
						<input type="password" class="form-control" name="cpassword"  placeholder="********">
					</div>
					<div class="text-right">
						<a class="forgot-link" href="javascript:void(0);">Already have an account?</a>
					</div>
					<button class="btn btn-primary btn-block btn-lg login-btn" data-dismiss="modal" aria-label="Close" type="submit">Signup</button>
					<div class="login-or">
						<span class="or-line"></span>
						<span class="span-or">or</span>
					</div>
					<div class="row form-row social-login">
						<div class="col-6">
							<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f mr-1"></i> Login</a>
						</div>
						<div class="col-6">
							<a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa- mr-1"></i> Login</a>
						</div>
					</div>
				</form>
				<!-- /Register Form -->

			</div>
		</div>
	</div>
</div>
<!-- /User Register Modal -->

<!-- Login Modal -->
<div class="modal account-modal fade" id="login_modal">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header p-0 border-0">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-header">
					<h3>Login <span>WM Service MarketPlace</span></h3>
				</div>
				<form>
					<div class="form-group form-focus">
						<label class="focus-label">Email</label>
						<input type="email" class="form-control" placeholder="sell@example.com">
					</div>
					<div class="form-group form-focus">
						<label class="focus-label">Password</label>
						<input type="password" class="form-control" placeholder="********">
					</div>
					<div class="text-right">
					</div>
					<button class="btn btn-primary btn-block btn-lg login-btn"  type="submit">Login</button>
					<div class="login-or">	<span class="or-line"></span>
						<span class="span-or">or</span>
					</div>
					<div class="row form-row social-login">
						<div class="col-6">	<a href="javascript:void(0);" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f mr-1"></i> Login</a>
						</div>
						<div class="col-6">	<a href="javascript:void(0);" class="btn btn-google btn-block"><i class="fab fa-google mr-1"></i> Login</a>
						</div>
					</div>
					<div class="text-center dont-have">Don't have an account? <a href="javascript:void(0);">Register</a>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<!-- /Login Modal -->
